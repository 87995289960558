<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper bgFFF" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.Rule_Name')">
            <el-input
              size="12"
              :maxlength="50"
              v-model.trim="formInline.titleStr"
              placeholder="请输入规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="道路组名称">
            <el-input
              size="12"
              :maxlength="50"
              v-model.trim="formInline.roadGroupNameStr"
              placeholder="请输入道路组名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t("button.search") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          stripe
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            width="90"
            align="center"
            v-if="$route.meta.authority.button.edit"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="editHandle(scope.row)">{{
                $t("button.edit")
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "chargeRules",
  data() {
    return {
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "title",
          label: this.$t("list.Rule_Name"),
          width: "",
        },
        {
          prop: "descMsg",
          label: this.$t("list.Rule_description"),
          width: "",
        },
        {
          prop: "roadGroups",
          width: "",
        },
        {
          prop: "bindDate",
          label: this.$t("list.Binding_time"),
          width: "",
        },
        {
          prop: "bindOperatorName",
          label: this.$t("list.Bind_operator"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        titleStr: "",
        roadGroupNameStr: "",
      },
    };
  },
  methods: {
    editHandle(data) {
      this.$router.push({ path: "/bindDetails", query: { topId: data.topId } });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.$axios
        .post("/acb/2.0/roadInEngine/queryFeeList", {
          data: {
            ...this.formInline,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1 ?? 0;
            this.tableData = res.value.list ?? [];
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
  },
  created() {
    this.searchData();
  },
  activated() {
    this.searchData();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
    margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
  }
}
</style>
